import React, { useEffect, useState } from "react";

import { getProductsCategories } from "Requests/Categories";
import { GetComboInProducts } from "Requests/Products";

import Input from "Components/Form/Input";
import Select from "Components/Form/Select";
import SelectSearch from "Components/Form/SelectSearch";
import PanelContent from "Components/Panels/PanelContent";

export function GeneralInfo({
  objectData,
  setObjectData,
  handleObjectChange,
  loading,
}) {
  const {
    name,
    result_schedule,
    exam_schedule,
    covenant,
    insurances,
    category_ids,
    region_id,
    channels,
    region,
    cashier,
    motion_code,
    slug,
    professional_code,
    type,
  } = objectData;

  const [optionsChannels, setOptionsChannel] = React.useState([]);
  const [categoriesAvaliable, setCategoriesAvaliable] = useState([]);

  async function fetchChannels() {
    const { channels } = await GetComboInProducts();

    const array = channels?.map((item) => ({
      id: item.id,
      name: item.name,
    }));
    setOptionsChannel(array);
  }

  const unitTypes = [
    { id: "exames", name: "Exames" },
    { id: "clinica", name: "Clínica" },
    { id: "domiciliar", name: "Domiciliar" },
  ];

  const getCategories = async () => {
    try {
      const response = await getProductsCategories();
      const formatData = response.map((category) => ({
        category: category.category,
        id: category.id,
      }));
      setCategoriesAvaliable(formatData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchChannels();

    getCategories();
  }, []);

  return (
    <PanelContent title="Informações Gerais" loading={loading}>
      <div className="row">
        <div className="md-6">
          <Input
            label="Nome da unidade*"
            type="text"
            maxLength={255}
            id="unit"
            value={name.value}
            error={name.error}
            onChange={({ target }) => handleObjectChange(target, "name")}
          />
        </div>
        <div className="md-6">
          <Input
            label="Slug*"
            type="text"
            id="slug"
            maxLength={255}
            value={slug.value}
            error={slug.error}
            onChange={({ target }) => handleObjectChange(target, "slug")}
          />
        </div>
        <div className="md-6">
          <SelectSearch
            label="Canais Disponíveis"
            id="channels"
            type="text"
            value={channels.value}
            error={channels.error}
            isMulti
            options={optionsChannels}
            loading={optionsChannels?.length === 0}
            readOnly={optionsChannels?.length === 0}
            valueKey="name"
            labelKey="name"
            placeholder="Canais permitido"
            onChange={(value) =>
              setObjectData({
                ...objectData,
                channels: {
                  ...objectData.channels,
                  error: "",
                  value,
                },
              })
            }
          />
        </div>
        <div className="md-6">
          <Input
            label="Motion Código*"
            type="text"
            id="motion_code"
            maxLength={4}
            value={motion_code.value}
            error={motion_code.error}
            onChange={({ target }) => handleObjectChange(target, "motion_code")}
          />
        </div>
        <div className="md-6">
          <SelectSearch
            label="Convênios"
            id="covenant_ids"
            type="text"
            value={insurances.value}
            error={insurances.error}
            isMulti
            options={covenant}
            loading={covenant?.length === 0}
            readOnly
            valueKey="covenant"
            labelKey="covenant"
            placeholder="Convênios permitidos"
            onChange={(value) =>
              setObjectData({
                ...objectData,
                insurances: {
                  ...objectData.insurances,
                  error: "",
                  value,
                },
              })
            }
          />
        </div>
        <div className="md-6">
          <SelectSearch
            label="Regiões*"
            id="region"
            type="text"
            value={region_id.value}
            error={region_id.error}
            isMoreSelect={false}
            isMulti
            options={region}
            loading={region?.length === 0}
            readOnly={region?.length === 0}
            valueKey="region"
            labelKey="region"
            placeholder="Regiões permitidas"
            onChange={(value) =>
              setObjectData({
                ...objectData,
                region_id: {
                  ...objectData.region_id,
                  error: "",
                  value,
                },
              })
            }
          />
        </div>
        <div className="md-6">
          <Input
            label="Caixa*"
            type="text"
            id="cashier"
            value={cashier.value}
            error={cashier.error}
            maxLength={45}
            onChange={({ target }) => handleObjectChange(target, "cashier")}
          />
        </div>
        <div className="md-6">
          <Input
            label="Agenda de Exames da Unidade"
            type="text"
            id="exam_schedule"
            maxLength={255}
            value={exam_schedule.value}
            error={exam_schedule.error}
            onChange={({ target }) =>
              handleObjectChange(target, "exam_schedule")
            }
          />
        </div>
        <div className="sm-6">
          <Select
            label="Tipo de Unidade*"
            id="type"
            placeholder="Informe o Tipo da Unidade"
            value={type.value}
            error={type.error}
            options={unitTypes}
            onChange={({ target }) => handleObjectChange(target, "type")}
          />
        </div>
        <div className="sm-6">
          <SelectSearch
            label="Serviços atendidos*"
            id="category_ids"
            type="text"
            value={category_ids.value}
            error={category_ids.error}
            isMulti
            options={categoriesAvaliable}
            loading={categoriesAvaliable?.length === 0}
            readOnly={categoriesAvaliable?.length === 0}
            valueKey="category"
            labelKey="category"
            required
            placeholder="Serviços atendidos"
            onChange={(value) =>
              setObjectData({
                ...objectData,
                category_ids: {
                  ...objectData.categories,
                  error: "",
                  value,
                },
              })
            }
          />
        </div>
        <div className="sm-6">
          <Input
            label="Calendario de resultados"
            type="text"
            id="result_schedule"
            maxLength={255}
            value={result_schedule.value}
            error={result_schedule.error}
            onChange={({ target }) =>
              handleObjectChange(target, "result_schedule")
            }
          />
        </div>

        <div className="sm-12">
          <Input
            label="Código da Unidade Profissional"
            type="text"
            id="professional_code"
            maxLength={255}
            value={professional_code.value}
            error={professional_code.error}
            onChange={({ target }) =>
              handleObjectChange(target, "professional_code")
            }
          />
        </div>
      </div>
    </PanelContent>
  );
}
