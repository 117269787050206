import { Field } from "formik";
import Input from "Components/Form/Input";
import { useBudgetOrderLocalStorage } from "Stores/budget-order";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { LinkTypeProps } from "./types";

export const LinkType = ({ values }: LinkTypeProps) => {
  const { order } = useBudgetOrderLocalStorage();

  return (
    <>
      <div
        style={{
          width: "100%",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          margin: 10,
          gap: 10,
        }}
      >
        <div style={{ width: "48%" }}>
          <Field
            name="name"
            id="name"
            label="NOME"
            component={Input}
            value={values.link.name}
            disabled
            readOnly
          />
        </div>
        <div style={{ width: "48%" }}>
          <Field
            name="cpf"
            id="cpf"
            label={
              order.payer_patient.document.type === "cpf" ? "CPF" : "PASSAPORTE"
            }
            component={Input}
            value={values.link.cpf}
            disabled
            readOnly
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          margin: 10,
          gap: 10,
        }}
      >
        <div style={{ width: "48%" }}>
          <Field
            name="email"
            id="email"
            label="EMAIL"
            component={Input}
            value={values.link.email}
            disabled
            readOnly
          />
        </div>
        <div style={{ width: "48%" }}>
          <Field
            name="phone"
            id="phone"
            label="Telefone"
            component={Input}
            value={values.link.phone}
            disabled
            readOnly
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          margin: 10,
          gap: 10,
        }}
      >
        <div style={{ width: "48%" }}>
          <Field
            name="value"
            id="value"
            label="Valor"
            component={Input}
            value={replaceStringNumber(values.link.received)}
            disabled
            readOnly
          />
        </div>
      </div>
    </>
  );
};
