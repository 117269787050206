import * as Yup from "yup";

import { SPeriods } from "./interface";

export const schema = Yup.object({
  product: Yup.string().required("Campo obrigatório"),
  slug: Yup.string().required("Campo obrigatório"),
  sku: Yup.string().required("Campo obrigatório"),
  stop_medication_obs: Yup.string().when(
    ["category_id", "stop_medication_id"],
    {
      is: (category_id: { id: number }, stop_medication_id: number) =>
        [5].includes(category_id.id) ||
        stop_medication_id === 2 ||
        !stop_medication_id,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Campo obrigatório"),
    }
  ),
  mt_code: Yup.number()
    .notRequired()
    .max(99999, "São permitidos no máximo 5 caracteres"),
  status_id: Yup.number().required("Campo obrigatório"),
  dose_amount: Yup.number()
    .min(1, "A quantidade mínima deve ser 1")
    .max(999, "São permitidos no máximo 4 caracteres"),
  dose_interval: Yup.number(),

  partners: Yup.array().when("category_id", {
    is: (category_id: { id: number }) => [4, 5, 8].includes(category_id.id),
    then: Yup.array().of(
      Yup.object({
        price: Yup.number().required("Campo obrigatório"),
        partner_id: Yup.number()
          .required("Campo obrigatório")
          .min(1, "Campo obrigatório"),
      })
    ),
    otherwise: Yup.array().of(
      Yup.object({
        price: Yup.number().required("Campo obrigatório"),
        partner_id: Yup.number()
          .required("Campo obrigatório")
          .min(1, "Campo obrigatório"),
        result_id: Yup.number().notRequired(),
        term: Yup.number().notRequired(),
      })
    ),
  }),
  services_periods: Yup.array().test(
    "services_periods",
    "Selecione início e fim corretamente.",
    (value: SPeriods[] | undefined) => {
      let skipValidation = true;

      value?.forEach((val) => {
        if (val.start_id && val.end_id) {
          return true;
        }

        if (val.start_id || val.end_id) {
          skipValidation = false;
        }

        return skipValidation;
      });

      return skipValidation;
    }
  ),

  product_pre_collection: Yup.array().of(
    Yup.object({
      collection_id: Yup.number().notRequired(),
      pre_collection_hours: Yup.number().when("collection_id", {
        is: 2,
        then: Yup.number()
          .nullable() // Permite que o valor seja null ou undefined
          .typeError("O campo deve ser um número")
          .required("Obrigatório"),
        otherwise: Yup.number(),
      }),
    })
  ),

  units: Yup.object().when(["services"], {
    is: (services: number[]) => services.some((i) => i === 1),
    then: Yup.object({
      include: Yup.array().min(1, "Selecione ao menos uma unidade"),
    }),
    otherwise: Yup.object({
      include: Yup.array().notRequired(),
    }),
  }),
  channels: Yup.array().min(1, "Selecione ao menos um canal"),
  genders: Yup.array().min(1, "Selecione ao menos um gênero"),
  services: Yup.array().min(1, "Selecione ao menos um tipo de atendimento"),
  kits: Yup.array().when(["category_id", "isPackage"], {
    is: (category_id: { id: number }, isPackage: boolean) =>
      [2, 4].includes(category_id.id) && isPackage,
    then: Yup.array().min(1, "Campo obrigatório").required(),
    otherwise: Yup.array().notRequired(),
  }),

  diet_avoid_preservatives_obs: Yup.string().when("diets", {
    is: (diets: any) => diets && diets.includes(4),
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string().notRequired(),
  }),

  diet_avoid_foods_obs: Yup.string().when("diets", {
    is: (diets: any) => diets && diets.includes(6),
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string().notRequired(),
  }),
});
