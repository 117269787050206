import { Fragment, useEffect, useRef, useState } from "react";
import {
  useBudgetOrder,
  useBudgetOrderLocalStorage,
} from "Stores/budget-order";
import { useHistory } from "react-router";

import { OrderHeading } from "Components/ServiceForm/OrderHeading";
import { CartOrderDetails } from "Pages/ServiceForm/components/Cart/CartOrderDetails/CartOrderDeatails/index";
import collectHouse from "Assets/Images/new_collect_house.svg";
import collectLabi from "Assets/Images/newCollectLabi.svg";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { toast } from "react-toastify";
import { useShallow } from "zustand/react/shallow";
import { cancelAppointment } from "Requests/CancelAppointment";
import { getRegions } from "Requests/Units";
import { InputCep } from "Components/Form/InputCep";
import { getCollectRangeCep } from "Requests/CollectRangeCep";
import { unmaskCEP } from "Utils/Masks";
import { maskCep } from "Components/Form/InputCep/functions";
import LoadingPurple from "Assets/Images/loading-purple.gif";
import { SelectRegions } from "Components/Form/SelectRegions";

import { RegionOptionType } from "Components/Form/SelectRegions/types";
import { getUnitiesWithSkus } from "Requests/Unities";
import { handleScroll } from "Utils/Scroll";
import { Button } from "Components/Button";
import { fetchProductRules } from "Requests/ProductRules";
import {
  PageBody,
  PageContainer,
  PageContent,
} from "../../../../ServiceForm/styles";
import { CollectCards, Container, TitleCheckoutCollect } from "../style";
import {
  CollectAssetType,
  BooleansType,
  ObjectsType,
  ArraysType,
  NumbersType,
  StringsType,
  FormValuesAdressType,
  ZipCodeType,
  UnityType,
} from "./types";
import { CardAttendanceType } from "./components/CardAttendanceType";
import { AttendanceBooleansType } from "./components/CardAttendanceType/types";
import { AddressPatientForm } from "./components/AddressPatientForm";
import {
  extractProductsIds,
  findMissingAttendances,
  getNextAttendanceIndex,
  mountSkusFromAttenndance,
} from "./functions";
import { ScheduleTimer } from "../ScheduleTimer";
import { CardAttendance } from "./components/CardAttendance";
import { UnityCard } from "./components/UnityCard";
import { SelectProductModalAttendance } from "./components/SelectModalAttendance";
import { CancelAppointmentModal } from "../CancelAppointment";

export const CreateOrderAttendance = () => {
  const history = useHistory();

  const titleRef = useRef<HTMLDivElement>(null);

  const [
    currentComponent,
    setCurrentComponent,
    setCurrentScreen,
    setEnabledProceedCollect,
    isViewBudget,
  ] = useBudgetOrder(
    useShallow((state) => [
      state.currentComponent,
      state.setCurrentComponent,
      state.setCurrentScreen,
      state.setEnabledProceedCollect,
      state.isViewBudget,
    ])
  );

  const { order, setOrder, currentPaymentType, setCurrentPaymentType } =
    useBudgetOrderLocalStorage();

  const [strings, setStrings] = useState<StringsType>({
    selectedId: "",
  });

  const [numbers, setNumbers] = useState<NumbersType>({
    currentIndex: 0,
  });

  const [booleans, setBooleans] = useState<BooleansType>({
    canProceed: true,
    disableButton: true,
    loadingRegions: false,
    loadingUnities: false,
    loadingCep: false,
    linkAttendancesModal: false,

    disableCepButton: true,
    hasAllAttendances: false,

    cancelAppointmentModal: false,

    disabledNextButton: true,
  });

  const [objects, setObjects] = useState<ObjectsType>({
    region: {
      id: 0,
      region: "",
    },
    unity: {
      id: 0,
      city: "",
      name: "",
      cep: "",
      coming_soon: false,
      neighbourhood: "",
      number: "",
      region: "",
      slug: "",
      street: "",
      type: "",
      products: [],
    },
    address: {
      city: "",
      neighborhood: "",
      state: "",
      street: "",
      zip_code: "",
      number: "",
      kml: {
        layer: "",
        map: "",
        polygon: "",
      },
      complement: "",
      reference_point: "",
    },
  });

  const [arrays, setArrays] = useState<ArraysType>({
    regions: [],
    unities: [],
  });

  const [homeCollectValue, setHomeCollectValue] = useState(0);

  useEffect(() => {
    setCurrentComponent("defaultCollect");
    setCurrentScreen("collect");

    if (order.formattedAttendances.length > 0) {
      verifyCheckAttendances();
    } else {
      setEnabledProceedCollect(false);
    }

    order.attendances.map((i) => {
      i.items.map((a) => {
        a.checked = false;
      });
    });
  }, []);

  useEffect(() => {
    getProductRules();
  }, [numbers.currentIndex]);

  useEffect(() => {
    if (order.budget_id === 0) return history.push("/pedidos");
  }, [order]);

  useEffect(() => {
    if (currentComponent === "defaultCollect") {
      setStrings({
        ...strings,
        selectedId: "",
      });
      verifyCheckAttendances();
      setObjects({
        ...objects,
        address: {
          city: "",
          neighborhood: "",
          state: "",
          street: "",
          zip_code: "",
          number: "",
          kml: {
            layer: "",
            map: "",
            polygon: "",
          },
          complement: "",
          reference_point: "",
        },
      });
    }
  }, [currentComponent]);

  useEffect(() => {
    if (booleans.hasAllAttendances)
      setBooleans({ ...booleans, disabledNextButton: false });
  }, [booleans.hasAllAttendances]);

  const collectTypeAssets: CollectAssetType[] = [
    {
      image: collectLabi,
      webpImage: collectLabi,
      label: "Unidade do Labi",
      type: "unity_collect",
    },
    {
      image: collectHouse,
      webpImage: collectHouse,
      label: "Labi em Casa",
      type: "home_collect",
    },
  ];

  const getProductRules = async () => {
    try {
      if (
        order.attendances[numbers.currentIndex].type === "home_collect" ||
        order.attendances[numbers.currentIndex].type === "both"
      ) {
        const products_ids: string[] = [];

        order.attendances[numbers.currentIndex].items.map((a) =>
          products_ids.push(String(a.product_id))
        );

        const productsRules: any = await fetchProductRules(
          products_ids?.join(",")
        );

        setHomeCollectValue(productsRules.home_collect_tax);
      }
    } catch (err) {
      console.error(err);
      toast.error("Erro ao buscar regras de produtos");
    }
  };

  const verifyCheckAttendances = () => {
    let indexMissingRegister = -1;
    const clonedFormattedAttendances = [...order.formattedAttendances];

    clonedFormattedAttendances.map((i, index) => {
      if (i.type === "unity_collect") {
        if (i.region_id === 0 || i.unity_id === 0) {
          indexMissingRegister = index;
        }
      } else if (i.type === "home_collect") {
        if (i.id_scheduling === 0) {
          indexMissingRegister = index;
        }
      }
    });

    if (indexMissingRegister != -1) {
      clonedFormattedAttendances.splice(indexMissingRegister, 1);

      setOrder({
        ...order,
        formattedAttendances: clonedFormattedAttendances,
      });
    }

    const nextIndex = getNextAttendanceIndex(
      order.attendances,
      clonedFormattedAttendances
    );

    if (nextIndex === -1) {
      setEnabledProceedCollect(true);
      return setBooleans((booleans) => ({
        ...booleans,
        hasAllAttendances: nextIndex === -1,
      }));
    }

    setNumbers((numbers) => ({
      ...numbers,
      currentIndex: nextIndex,
    }));
  };

  const renderMainTitle = () => {
    const styles = {
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
    };

    if (order.budget_id !== 0) {
      return (
        <div style={{ ...styles, width: "100%" }} ref={titleRef}>
          <TitleCheckoutCollect>
            Onde você quer fazer o{" "}
            {order.attendances[numbers.currentIndex].title.toLowerCase()}?
          </TitleCheckoutCollect>
        </div>
      );
    }

    return <></>;
  };

  const handleCancelScheduleWithoutRedirect = async () => {
    const idSchedule = order.formattedAttendances.find(
      (i) => i.id_scheduling != 0 && i.type === "home_collect"
    )?.id_scheduling;
    if (idSchedule) await cancelAppointment(idSchedule);
  };

  const handleCancelSchedule = async () => {
    try {
      const idSchedule = order.formattedAttendances.find(
        (i) => i.id_scheduling != 0 && i.type === "home_collect"
      )?.id_scheduling;
      if (idSchedule) {
        await cancelAppointment(idSchedule);
        setOrder({
          budget_id: 0,
          appointment_type_id: "",
          payment_info: [],
          isTaxInserted: false,
          payer_patient: {
            id: 0,
            birthdate: "",
            document: {
              id: 0,
              number: "",
              type: "",
              country_id: 0,
            },
            email: "",
            gender_id: 0,
            mother_name: "",
            name: "",
            phone: {
              id: 0,
              ddd: 0,
              ddi: 0,
              phone: 0,
            },
            unborn: false,
            social_name: "",
          },
          lead: {
            id: 0,
            ddd: null,
            phone: "",
            email: null,
            name: "",
          },
          attendances: [],
          itemsQuantity: 0,
          totalPrice: "0,00",
          budget_type: 1,
          coupon_percentage_discount: 0,
          homeCollectValue: 0,
          isCovenant: false,
          discounts: 0,
          subtotal: 0,
          deliveryTime: "",
          items: [],
          product_categories: [],
          formattedAttendances: [],
          session_start: "",
          session_end: "",
          lastAddress: {
            street: "",
            number: "",
            uf: "",
            neighborhood: "",
            city: "",
            channel_id: 4,
            complement: "",
            country_id: 26,
            default: true,
            reference_point: "",
            type_id: 1,
            zip_code: "",
          },
          attendanceLabel: [],
          dependents: [],
          patients: [],
          patientWithProducts: [],
          cupon_code: "",
          home_collected: false,
          home_collect_value: "",
          orderData: {
            channel_id: 4,
            order_type: 1,
            appointment_type_id: "",
            commercial_category: 63,
            commercial_document_number: "",
            payer_id: 0,
            order: {
              budget_id: 0,
              subtotal: "",
              discounts: "",
              total: 0,
              coupon_code: null,
              home_collected: false,
              home_collect_value: "",
              home_collect_date: "",
              vaccine_cards: [],
              cart: [],
              home_collect_schedule: {
                id_home_collected: 0,
                home_collect_date: "",
                zip_code: "",
                country: "Brasil",
                uf: "",
                city: "",
                neighborhood: "",
                street: "",
                number: "",
                complement: "",
                reference_point: "",
                address_type_id: 1,
              },
            },
          },
        });
      }
    } catch (err) {
      console.error(err);
      toast.error("Erro ao cancelar o agendamento.");
    }
  };

  const handleSelectAttendanceType = async (id: string) => {
    if (id === strings.selectedId) return;
    // Caso o Usuário selecione a mesma coisa, a função morre acima.

    setStrings({
      ...strings,
      selectedId: id,
    });

    // Verificação se existe atendimentos hibridos
    const hasBothAttendances = order.attendances.some((i) => i.type === "both");
    const hasOnlyBothAttendances = order.attendances.every(
      (i) => i.type === "both"
    );

    if (order.formattedAttendances.length === 0) {
      if (hasBothAttendances && hasOnlyBothAttendances) {
        // Caso tenha apenas um atendimento e itens hibridos, com base no tipo de atendimento selecionado.
        return createAttendanceWithoutModalSingle(id);
      }

      if (
        hasBothAttendances &&
        order.attendances[numbers.currentIndex].type === "both"
      ) {
        return verifyAutomaticLink(id);
      }

      if (hasBothAttendances && !hasOnlyBothAttendances) {
        return setBooleans({
          ...booleans,
          linkAttendancesModal: true,
          disabledNextButton: booleans.hasAllAttendances,
        });
      }
    }

    createAttendanceBasedInType(id);
  };

  const verifyAutomaticLink = (id: string) => {
    if (order.attendances[numbers.currentIndex].type === "both") {
      if (id === "home_collect") {
        const nextIndex = order.attendances.findIndex(
          (a) => a.type === "home_collect"
        );
        if (nextIndex >= 0) {
          return createAttendanceWithoutModalMultipleWithNext(nextIndex, id);
        }
        createAttendanceWithoutModalMultiple(id);
      }
      if (id === "unity_collect") {
        const nextIndex = order.attendances.findIndex(
          (a) => a.type === "unity_collect"
        );
        if (nextIndex >= 0) {
          return createAttendanceWithoutModalMultipleWithNext(nextIndex, id);
        }
        createAttendanceWithoutModalMultiple(id);
      }
    }
  };

  const createAttendanceWithoutModalSingle = (id: string) => {
    const products_ids: number[] = order.items.map((a) => a.product_id);
    const formattedAttendances = booleans.hasAllAttendances
      ? []
      : [...order.formattedAttendances];

    formattedAttendances.push({
      ids_attendance: [order.attendances[numbers.currentIndex].id],
      type: id == "unity_collect" ? "unity_collect" : "home_collect", // Tipo padrão (será atualizado conforme a condição)
      region_id: 0,
      unity_id: 0,
      id_scheduling: 0,
      home_collect_date: "",
      calendar_id: "",
      unity_name: "",
      home_collect_hour: "",
      products_ids,
    });

    if (id === "home_collect" && !order.isTaxInserted) {
      const totalPrice =
        order.homeCollectValue === 0 || order.homeCollectValue === "0,00"
          ? Number(replaceStringNumber(order.totalPrice)) + homeCollectValue
          : order.totalPrice;

      setOrder({
        ...order,
        homeCollectValue: replaceStringNumber(homeCollectValue),
        home_collect_value: String(replaceStringNumber(homeCollectValue)),
        subtotal: order.subtotal,
        totalPrice: String(replaceStringNumber(totalPrice)),
        formattedAttendances,
        attendanceLabel: booleans.hasAllAttendances
          ? []
          : order.attendanceLabel,
        isTaxInserted: true,
      });
    } else if (order.isTaxInserted) {
      const totalPrice =
        order.homeCollectValue === 0 || order.homeCollectValue === "0,00"
          ? Number(replaceStringNumber(order.totalPrice)) -
            Number(replaceStringNumber(order.homeCollectValue))
          : order.totalPrice;

      setOrder({
        ...order,
        formattedAttendances,
        attendanceLabel: booleans.hasAllAttendances
          ? []
          : order.attendanceLabel,
        totalPrice:
          homeCollectValue !== 0 ? String(totalPrice) : order.totalPrice,
        isTaxInserted: false,
        home_collect_value: "0,00",
        homeCollectValue: "0,00",
      });
    } else {
      setOrder({
        ...order,
        formattedAttendances,
        attendanceLabel: booleans.hasAllAttendances
          ? []
          : order.attendanceLabel,
      });
    }

    setBooleans((prevState) => ({
      ...prevState,
      hasAllAttendances: false,
      loadingRegions: true,
      disabledNextButton: true,
    }));

    fetchAndMountRegions();

    setBooleans((prevState) => ({
      ...prevState,
      loadingRegions: false,
    }));
  };

  const createAttendanceWithoutModalMultipleWithNext = (
    nextIndex: number,
    id: string
  ) => {
    const next_products_ids: number[] = order.attendances[nextIndex].items.map(
      (a) => a.product_id
    );
    const products_ids: number[] = order.attendances[
      numbers.currentIndex
    ].items.map((a) => a.product_id);

    const mixed_ids = next_products_ids.concat(products_ids);

    const formattedAttendances = booleans.hasAllAttendances
      ? []
      : [...order.formattedAttendances];

    const attendance_ids = [
      order.attendances[numbers.currentIndex].id,
      order.attendances[nextIndex].id,
    ];

    formattedAttendances.push({
      ids_attendance: attendance_ids,
      type: id == "unity_collect" ? "unity_collect" : "home_collect", // Tipo padrão (será atualizado conforme a condição)
      region_id: 0,
      unity_id: 0,
      id_scheduling: 0,
      home_collect_date: "",
      calendar_id: "",
      unity_name: "",
      home_collect_hour: "",
      products_ids: mixed_ids,
    });

    if (id === "home_collect" && !order.isTaxInserted) {
      const totalPrice =
        order.homeCollectValue === 0 || order.homeCollectValue === "0,00"
          ? Number(replaceStringNumber(order.totalPrice)) + homeCollectValue
          : order.totalPrice;

      setOrder({
        ...order,
        homeCollectValue: replaceStringNumber(homeCollectValue),
        home_collect_value: String(replaceStringNumber(homeCollectValue)),
        subtotal: order.subtotal,
        totalPrice: String(replaceStringNumber(totalPrice)),
        formattedAttendances,
        attendanceLabel: booleans.hasAllAttendances
          ? []
          : order.attendanceLabel,
        isTaxInserted: true,
      });
    } else {
      setOrder({
        ...order,
        formattedAttendances,
        attendanceLabel: booleans.hasAllAttendances
          ? []
          : order.attendanceLabel,
      });
    }

    if (id === "unity_collect") {
      setBooleans((prevState) => ({
        ...prevState,
        loadingRegions: true,
        disabledNextButton: booleans.hasAllAttendances,
      }));

      fetchAndMountRegions();

      return setBooleans((prevState) => ({
        ...prevState,
        loadingRegions: false,
      }));
    }

    setBooleans((prevState) => ({
      ...prevState,
      hasAllAttendances: false,
    }));
  };

  const createAttendanceWithoutModalMultiple = (id: string) => {
    const products_ids: number[] = order.attendances[
      numbers.currentIndex
    ].items.map((a) => a.product_id);

    const formattedAttendances = booleans.hasAllAttendances
      ? []
      : [...order.formattedAttendances];

    formattedAttendances.push({
      ids_attendance: [order.attendances[numbers.currentIndex].id],
      type: id == "unity_collect" ? "unity_collect" : "home_collect", // Tipo padrão (será atualizado conforme a condição)
      region_id: 0,
      unity_id: 0,
      id_scheduling: 0,
      home_collect_date: "",
      calendar_id: "",
      unity_name: "",
      home_collect_hour: "",
      products_ids,
    });

    if (id === "home_collect" && !order.isTaxInserted) {
      const totalPrice =
        order.homeCollectValue === 0 || order.homeCollectValue === "0,00"
          ? Number(replaceStringNumber(order.totalPrice)) + homeCollectValue
          : order.totalPrice;

      setOrder({
        ...order,
        homeCollectValue: replaceStringNumber(homeCollectValue),
        home_collect_value: String(replaceStringNumber(homeCollectValue)),
        subtotal: order.subtotal,
        totalPrice: String(replaceStringNumber(totalPrice)),
        formattedAttendances,
        attendanceLabel: booleans.hasAllAttendances
          ? []
          : order.attendanceLabel,
        isTaxInserted: true,
      });
    } else {
      setOrder({
        ...order,
        formattedAttendances,
        attendanceLabel: booleans.hasAllAttendances
          ? []
          : order.attendanceLabel,
      });
    }

    if (id === "unity_collect") {
      setBooleans((prevState) => ({
        ...prevState,
        loadingRegions: true,
        disabledNextButton: booleans.hasAllAttendances,
        hasAllAttendances: false,
      }));

      fetchAndMountRegions();

      return setBooleans((prevState) => ({
        ...prevState,
        loadingRegions: false,
        disabledNextButton: true,
        hasAllAttendances: false,
      }));
    }

    setBooleans((prevState) => ({
      ...prevState,
      hasAllAttendances: false,
    }));
  };

  const createAttendanceBasedInType = (id: string) => {
    try {
      if (id === "unity_collect") handleCreateUnityAttendance();
      if (id === "home_collect") handleCreateHomeAttendance();
    } catch (err) {
      console.error("Algo deu errado", err);
    }
  };

  const handleCreateUnityAttendance = async () => {
    try {
      setBooleans({
        ...booleans,
        loadingRegions: true,
        disabledNextButton: booleans.hasAllAttendances,
      });

      await fetchAndMountRegions();

      setBooleans({
        ...booleans,
        loadingRegions: false,
      });
      const clonedFormattedAttendances = booleans.hasAllAttendances
        ? []
        : [...order.formattedAttendances];

      const missingAttendances = findMissingAttendances(
        order.attendances,
        clonedFormattedAttendances,
        order.attendances[numbers.currentIndex].id
      );

      const hasMissingAttendance = missingAttendances.length > 0;

      const extractedProductsIds: number[] = extractProductsIds(
        order.attendances,
        numbers.currentIndex
      );
      let isCanceledSchedule = false;

      // Quando você quer trocar o tipo de atendimento após ter clicado em um
      const foundIndex = clonedFormattedAttendances.findIndex((a) =>
        a.ids_attendance.includes(order.attendances[numbers.currentIndex].id)
      );
      if (foundIndex >= 0) {
        clonedFormattedAttendances.splice(foundIndex, 1);
      }

      if (
        hasMissingAttendance &&
        (missingAttendances[0].type === "unity_collect" ||
          missingAttendances[0].type === "both")
      ) {
        const missedProductsIds = missingAttendances[0].items.map(
          (i) => i.product_id
        );

        clonedFormattedAttendances.push({
          ids_attendance: [
            order.attendances[numbers.currentIndex].id,
            missingAttendances[0].id,
          ],
          products_ids: [...extractedProductsIds, ...missedProductsIds],
          id_scheduling: 0,
          region_id: 0,
          type: "unity_collect",
          unity_id: 0,
          home_collect_date: "",
          calendar_id: "",
          unity_name: "",
          home_collect_hour: "",
        });
      } else {
        clonedFormattedAttendances.push({
          ids_attendance: [order.attendances[numbers.currentIndex].id],
          products_ids: extractedProductsIds,
          id_scheduling: 0,
          region_id: 0,
          type: "unity_collect",
          unity_id: 0,
          home_collect_date: "",
          calendar_id: "",
          unity_name: "",
          home_collect_hour: "",
        });
      }

      setBooleans((prevState) => ({
        ...prevState,
        hasAllAttendances: false,
      }));

      if (booleans.hasAllAttendances) {
        const foundAttendances = order.formattedAttendances.find((a) =>
          a.ids_attendance.includes(order.attendances[numbers.currentIndex].id)
        );

        handleCancelScheduleWithoutRedirect();

        isCanceledSchedule = true;
      }

      if (order.isTaxInserted) {
        const totalPrice =
          Number(replaceStringNumber(order.totalPrice)) -
          Number(replaceStringNumber(order.homeCollectValue));
        setOrder({
          ...order,
          formattedAttendances: clonedFormattedAttendances,
          attendanceLabel: booleans.hasAllAttendances
            ? []
            : order.attendanceLabel,
          totalPrice:
            homeCollectValue !== 0 ? String(totalPrice) : order.totalPrice,
          isTaxInserted: false,
          home_collect_value: "0,00",
          homeCollectValue: "0,00",
          session_start: isCanceledSchedule ? "" : order.session_start,
          session_end: isCanceledSchedule ? "" : order.session_end,
        });
      } else {
        setOrder({
          ...order,
          formattedAttendances: clonedFormattedAttendances,
          attendanceLabel: booleans.hasAllAttendances
            ? []
            : order.attendanceLabel,
          session_start: isCanceledSchedule ? "" : order.session_start,
          session_end: isCanceledSchedule ? "" : order.session_end,
        });
      }
    } catch (err) {
      console.error("Erro ao criar atendimento unidade", err);
    }
  };

  const handleCompleteCreateUnityAttendance = async () => {
    const nextIndex = getNextAttendanceIndex(
      order.attendances,
      order.formattedAttendances
    );

    // Verifica se há mais algum atendimento que não teve dados preenchidos
    if (nextIndex === -1) {
      handleScroll(titleRef);
      setBooleans({
        ...booleans,
        hasAllAttendances: true,
      });
      setStrings({
        ...strings,
        selectedId: "",
      });
      setEnabledProceedCollect(true);
      setBooleans({
        ...booleans,
        disabledNextButton: true,
      });
      history.push("/pedidos/criar-pedido/identificacao");
      return handleFinishUnityAttendance();
    }

    if (
      (order.attendances[numbers.currentIndex].type === "unity_collect" ||
        order.attendances[numbers.currentIndex].type === "both") &&
      (order.attendances[nextIndex].type === "both" ||
        order.attendances[nextIndex].type === "home_collect")
    ) {
      handleFinishUnityAttendance();
      handleScroll(titleRef);
      setStrings({
        ...strings,
        selectedId: "",
      });
      setNumbers({
        ...numbers,
        currentIndex: nextIndex,
      });
    }

    setBooleans({
      ...booleans,
      disabledNextButton: true,
    });
  };

  const handleCreateHomeAttendance = () => {
    try {
      const clonedFormattedAttendances = booleans.hasAllAttendances
        ? []
        : [...order.formattedAttendances];
      const missingAttendances = findMissingAttendances(
        order.attendances,
        clonedFormattedAttendances,
        order.attendances[numbers.currentIndex].id
      );

      const hasMissingAttendance = missingAttendances.length > 0;

      const extractedProductsIds: number[] = extractProductsIds(
        order.attendances,
        numbers.currentIndex
      );

      // Quando você quer trocar o tipo de atendimento após ter clicado em um
      const foundIndex = order.formattedAttendances.findIndex((a) =>
        a.ids_attendance.includes(order.attendances[numbers.currentIndex].id)
      );
      if (foundIndex >= 0) {
        clonedFormattedAttendances.splice(foundIndex, 1);
      }
      let isCanceledSchedule = false;

      if (
        hasMissingAttendance &&
        (missingAttendances[0].type === "home_collect" ||
          missingAttendances[0].type === "both")
      ) {
        const missedProductsIds = missingAttendances[0].items.map(
          (i) => i.product_id
        );

        clonedFormattedAttendances.push({
          ids_attendance: [
            order.attendances[numbers.currentIndex].id,
            missingAttendances[0].id,
          ],
          products_ids: [...extractedProductsIds, ...missedProductsIds],
          id_scheduling: 0,
          region_id: 0,
          type: "home_collect",
          unity_id: 0,
          home_collect_date: "",
          calendar_id: "",
          unity_name: "",
          home_collect_hour: "",
        });
      } else {
        clonedFormattedAttendances.push({
          ids_attendance: [order.attendances[numbers.currentIndex].id],
          products_ids: extractedProductsIds,
          id_scheduling: 0,
          region_id: 0,
          type: "home_collect",
          unity_id: 0,
          home_collect_date: "",
          calendar_id: "",
          unity_name: "",
          home_collect_hour: "",
        });
      }

      if (booleans.hasAllAttendances) {
        handleCancelScheduleWithoutRedirect();

        isCanceledSchedule = true;
      }

      if (homeCollectValue != 0 && !order.isTaxInserted) {
        const totalPrice =
          order.homeCollectValue === 0 || order.homeCollectValue === "0,00"
            ? Number(replaceStringNumber(order.totalPrice)) + homeCollectValue
            : order.totalPrice;

        setOrder({
          ...order,
          homeCollectValue: replaceStringNumber(homeCollectValue),
          home_collect_value: String(replaceStringNumber(homeCollectValue)),
          subtotal: order.subtotal,
          totalPrice: String(replaceStringNumber(totalPrice)),
          formattedAttendances: clonedFormattedAttendances,
          attendanceLabel: booleans.hasAllAttendances
            ? []
            : order.attendanceLabel,
          isTaxInserted: true,
          session_start: isCanceledSchedule ? "" : order.session_start,
          session_end: isCanceledSchedule ? "" : order.session_end,
        });
      } else {
        setOrder({
          ...order,
          formattedAttendances: clonedFormattedAttendances,
          attendanceLabel: booleans.hasAllAttendances
            ? []
            : order.attendanceLabel,
          session_start: isCanceledSchedule ? "" : order.session_start,
          session_end: isCanceledSchedule ? "" : order.session_end,
        });
      }

      setBooleans((prevState) => ({
        ...prevState,
        hasAllAttendances: false,
      }));
    } catch (err) {
      console.error("Erro ao criar atendimento domiciliar", err);
    }
  };

  const handleFinishUnityAttendance = () => {
    const clonedAttendanceLabel = [...order.attendanceLabel];
    const clonedFormattedAttendances = [...order.formattedAttendances];

    const formattedAttendanceIndex = order.formattedAttendances.findIndex(
      (item) =>
        item.ids_attendance.includes(order.attendances[numbers.currentIndex].id)
    );

    clonedFormattedAttendances[formattedAttendanceIndex] = {
      ...clonedFormattedAttendances[formattedAttendanceIndex],
      region_id: objects.region.id,
      unity_id: objects.unity.id,
      unity_name: objects.unity.name,
    };

    clonedAttendanceLabel.push({
      collect_address: "",
      label: order.attendances[numbers.currentIndex].title,
      unity: objects.unity.name,
      ids_attendance: [order.attendances[numbers.currentIndex].id],
    });

    setOrder({
      ...order,
      formattedAttendances: clonedFormattedAttendances,
      attendanceLabel: clonedAttendanceLabel,
    });
  };

  const fetchAndMountRegions = async () => {
    setObjects({
      ...objects,
      region: {
        id: 0,
        region: "",
      },
    });

    const regions = await getRegions().then((data) => data);

    setArrays({
      ...arrays,
      regions,
    });
  };

  const handleChangeCep = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const cepValue = maskCep(e.target.value) || "";

      setObjects((objects) => ({
        ...objects,
        address: {
          ...objects.address,
          zip_code: cepValue,
        },
      }));

      if (cepValue.length < 9) return;

      if (cepValue) {
        const zip_code = unmaskCEP(cepValue);

        const origin = "https://colabi-feature.labiexames.com.br/";

        let foundAddress: ZipCodeType = {
          address: {
            city: "",
            neighborhood: "",
            state: "",
            street: "",
            zip_code: "",
          },
          kml: {
            layer: "",
            map: "",
            polygon: "",
          },
        };

        setBooleans({
          ...booleans,
          loadingCep: true,
          disabledNextButton: booleans.hasAllAttendances,
        });

        const fetchedData = await getCollectRangeCep({ zip_code }).then(
          (fetchedData: any) => (fetchedData ? fetchedData.data.data : null)
        );

        foundAddress = {
          address: fetchedData
            ? { ...fetchedData.address, zip_code: cepValue }
            : {
                city: "",
                neighborhood: "",
                state: "",
                street: "",
                zip_code: cepValue,
              },
          kml: fetchedData
            ? fetchedData.kml
            : {
                layer: "",
                map: "",
                polygon: "",
              },
        };

        setBooleans({
          ...booleans,
          loadingCep: false,
        });

        setObjects({
          ...objects,
          address: {
            ...foundAddress.address,
            number: "",
            kml: { ...foundAddress.kml },
            complement: "",
            reference_point: "",
          },
        });

        if (fetchedData) {
          toast.success("Seu CEP está coberto pela Coleta Domiciliar do Labi.");
          setCurrentComponent("homeCollect");
        }
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err?.message);
    }
  };

  const handleSelectRegion = async (value: RegionOptionType) => {
    try {
      setBooleans({
        ...booleans,
        loadingUnities: true,
      });

      setObjects({
        ...objects,
        region: value,
        unity: {
          id: 0,
          city: "",
          name: "",
          cep: "",
          coming_soon: false,
          neighbourhood: "",
          number: "",
          region: "",
          slug: "",
          street: "",
          type: "",
          products: [],
        },
      });

      let formattedSkus = "";

      formattedSkus = mountSkusFromAttenndance(order, numbers.currentIndex);

      const unities = await getUnitiesWithSkus(formattedSkus, value.id).then(
        (data) => data
      );

      setArrays({
        ...arrays,
        unities,
      });

      setBooleans({
        ...booleans,
        loadingUnities: false,
      });
    } catch (err) {
      console.error("Algo deu errado!", err);
    }
  };

  const handleSelectUnity = (unity: UnityType) => {
    setObjects({
      ...objects,
      unity,
    });

    // handleScroll(nextButtonRef);
    setBooleans({
      ...booleans,
      disabledNextButton: false,
    });
  };

  const handleCloseProductModal = (clearField: boolean) => {
    if (strings.selectedId === "unity_collect") {
      fetchAndMountRegions();
      setBooleans({ ...booleans, linkAttendancesModal: false });
    } else {
      setBooleans({ ...booleans, linkAttendancesModal: false });
    }

    if (clearField) {
      return setStrings({
        ...strings,
        selectedId: "",
      });
    }
  };

  const handleCloseCancelModal = () => {
    setBooleans({
      ...booleans,
      cancelAppointmentModal: false,
    });
    const { budget_id } = order;

    handleCancelSchedule();

    if (isViewBudget) {
      history.push(`/orcamento/${budget_id}`);
    } else {
      history.push(
        `/ficha-de-atendimento/person/selecionar-produtos/${budget_id}?btype=private`
      );
    }
  };

  const handleOpenCancelModal = () => {
    setBooleans({ ...booleans, cancelAppointmentModal: true });
  };

  const handleProceed = () => {
    if (
      strings.selectedId === "unity_collect" &&
      objects.unity.id != 0 &&
      objects.region.id != 0
    )
      return handleCompleteCreateUnityAttendance();

    if (booleans.hasAllAttendances)
      return history.push("/pedidos/criar-pedido/identificacao");
  };

  useEffect(() => {
    if (order.payment_info.length) {
      const spreadedOrder = {
        ...order,
        payment_info: [],
      };

      setOrder(spreadedOrder);
    }

    if (currentPaymentType) {
      setCurrentPaymentType([""]);
    }
  }, []);

  return (
    <PageContainer className="entry-app-content entry-budget">
      {order.budget_id != 0 ? (
        <>
          <PageContent className="content-holder page-budget">
            <OrderHeading
              list={[
                {
                  path: "/orcamentos",
                  label: "Orçamento",
                  current: false,
                },
                {
                  path: "#",
                  label: "Converter",
                  current: false,
                },
                {
                  path: "#",
                  label: "Atendimento",
                  current: true,
                },
              ]}
              title="Converter em Pedido"
              payerPatient={order.payer_patient}
            />

            <PageBody className="cart-order-budget">
              <Container className="card-search-information gray-background">
                {order.session_end ? (
                  <ScheduleTimer
                    expired={() => handleOpenCancelModal()}
                    content="Para garantir que sua reserva seja confirmada, por favor finalize a identificação e o pagamento do seu pedido dentro do prazo ao lado. Depois desse período sua reserva será liberada para venda novamente."
                  />
                ) : (
                  <></>
                )}

                {order.attendanceLabel.map((i, index) => (
                  <Fragment key={index}>
                    <CardAttendance
                      title={i.label}
                      collect={i.collect_address}
                      unity={i.unity}
                    />
                  </Fragment>
                ))}

                {currentComponent === "defaultCollect" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        justifyItems: "center",
                      }}
                      ref={titleRef}
                    >
                      {renderMainTitle()}
                    </div>

                    <CollectCards>
                      {collectTypeAssets.map((i, index) => {
                        const validations: AttendanceBooleansType = {
                          inactive: false,
                          partnerProduct: order.product_categories.some((i) =>
                            i.category_name.includes("Pague Menos")
                          ), // Verifiacr posteriomente, quando categoria retornar no item do orçamento
                          firstCollectHome: order.items.some(
                            (item) => item.collect_type === "home_collect"
                          ),
                          isCorona: order.items.some((i) =>
                            i.product_code.includes("COVID19R")
                          ),
                          isVaccine: order.items.some(
                            (i) =>
                              i.product_code !== "75" &&
                              i.category === "Vacinas"
                          ), // code 75 is flu vaccine
                          isAntigen: order.items.some((i) =>
                            i.product_code.includes("COVID19AI")
                          ),
                          isFluVaccine: order.items.some(
                            (i) => i.product_code === "75"
                          ),
                          isTetravalentVaccine: order.items.some(
                            (i) => i.product_code === "VGT"
                          ),
                        };

                        return (
                          <Fragment key={index}>
                            <CardAttendanceType
                              id={i.type}
                              currentAttendance={
                                order.attendances[numbers.currentIndex]
                              }
                              formattedAttendances={order.formattedAttendances}
                              activeCollect=""
                              image={i.image}
                              webpImage={i.webpImage}
                              items={order.items}
                              label={i.label}
                              cost={replaceStringNumber(order.homeCollectValue)}
                              validations={validations}
                              skuItems={order.items.map((a) => a.product_code)}
                              handleSelect={handleSelectAttendanceType}
                              selected={strings.selectedId}
                              disableOption={false}
                              homeCollectValue={homeCollectValue}
                              order={order}
                              homeCollectTax={homeCollectValue}
                            />
                          </Fragment>
                        );
                      })}
                    </CollectCards>

                    {/* Caso seja Unidade - Início */}
                    {strings.selectedId === "unity_collect" && (
                      <>
                        <div style={{ marginTop: "1em", width: "50%" }}>
                          <h5 style={{ color: "var(--color-purple)" }}>
                            Escolha uma unidade:
                          </h5>
                          <SelectRegions
                            id="select_regions"
                            label="Selecione a Região"
                            error={false}
                            small={false}
                            options={arrays.regions}
                            value={objects.region}
                            loading={booleans.loadingRegions}
                            placeholder="Selecione a Região"
                            onChange={handleSelectRegion}
                          />
                        </div>

                        {booleans.loadingUnities ? (
                          <div style={{ width: "50%" }}>
                            <img
                              className="loading loading-small loading-align-center"
                              src={LoadingPurple}
                              alt="Labi Saúde - Carregando"
                              loading="lazy"
                              style={{ marginTop: "1em" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {objects.region.id !== 0 && arrays.unities.length > 0 && (
                          <div style={{ whiteSpace: "pre", marginTop: "1em" }}>
                            <h3
                              style={{
                                color: "var(--color-dark-gray)",
                                fontSize: "0.75em",
                                lineHeight: "1.4375em",
                                fontWeight: 600,
                                textTransform: "uppercase",
                                marginLeft: "0.5em",
                              }}
                              className="text-gray"
                            >
                              {`${arrays.unities.length} unidades`}
                            </h3>
                          </div>
                        )}

                        {arrays.unities.length === 0 &&
                        objects.region.id !== 0 &&
                        !booleans.loadingUnities ? (
                          <span
                            style={{
                              color: "var(--color-purple)",
                              marginTop: 10,
                            }}
                          >
                            Infelizmente não há nenhuma unidade cadastrada na
                            região
                          </span>
                        ) : (
                          <>
                            {arrays.unities.map((i, index) => (
                              <div
                                key={index}
                                style={{
                                  marginTop: "1em",
                                  gap: "1em",
                                  width: "50%",
                                }}
                              >
                                <UnityCard
                                  unity={i}
                                  selectedUnity={objects.unity}
                                  handleSelectUnity={handleSelectUnity}
                                  activeUnities={arrays.unities}
                                  lengthItems={order.items.length}
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    )}
                    {/* Caso seja Unidade - Fim */}

                    {/* Caso seja Coleta Domiciliar */}
                    {strings.selectedId === "home_collect" && (
                      <>
                        <div style={{ width: "50%" }}>
                          <TitleCheckoutCollect>
                            Informe seu endereço
                          </TitleCheckoutCollect>
                          <InputCep
                            label="CEP"
                            mask="99999-999"
                            id="cep"
                            value={objects.address.zip_code}
                            error={false}
                            placeholder="00000-00"
                            onChange={(e) => handleChangeCep(e)}
                          />

                          {booleans.loadingCep && (
                            <img
                              className="loading loading-small loading-align-center"
                              src={LoadingPurple}
                              alt="Labi Saúde - Carregando"
                              loading="lazy"
                              style={{ marginTop: "1em" }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div style={{ width: "100%", maxWidth: "100%" }}>
                    <AddressPatientForm
                      address={objects.address}
                      setAddress={(value: FormValuesAdressType) =>
                        setObjects((objects) => ({
                          ...objects,
                          address: value,
                        }))
                      }
                      currentIndex={numbers.currentIndex}
                      setCurrentIndex={(value: number) =>
                        setNumbers((numbers) => ({
                          ...numbers,
                          currentIndex: value,
                        }))
                      }
                      order={order}
                      setOrder={setOrder}
                      setSelectedId={(value: string) =>
                        setStrings((strings) => ({
                          ...strings,
                          selectedId: value,
                        }))
                      }
                      setHasAllAttendances={(value: boolean) =>
                        setBooleans((booleans) => ({
                          ...booleans,
                          hasAllAttendances: value,
                        }))
                      }
                      setEnabledButton={(value: boolean) =>
                        setBooleans({ ...booleans, disabledNextButton: value })
                      }
                      enabledButton={booleans.disabledNextButton}
                    />
                  </div>
                )}

                {currentComponent === "defaultCollect" ? (
                  <div
                    style={{
                      bottom: 0,
                      width: "100%",
                      backdropFilter: "saturate(180%) blur(5px)", // Aplica desfoque e saturação
                      WebkitBackdropFilter: "saturate(180%) blur(20px)", // Compatibilidade com Safari
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1em 0",
                      borderRadius: "1em",
                    }}
                  >
                    <div style={{ width: "10em" }} />

                    <div style={{ width: "10em" }}>
                      <Button
                        width="full"
                        minHeight="3.7em"
                        fontWeight="600"
                        onClick={() => handleProceed()}
                        disabled={booleans.disabledNextButton}
                      >
                        Avançar
                      </Button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </Container>
              <CartOrderDetails />
            </PageBody>
          </PageContent>
          {booleans.linkAttendancesModal ? (
            <SelectProductModalAttendance
              selectedId={strings.selectedId}
              isOpen={booleans.linkAttendancesModal}
              handleClose={(clearField) => handleCloseProductModal(clearField)}
              attendances={order.attendances}
              currentAttendance={order.attendances[numbers.currentIndex]}
              order={order}
              setOrder={setOrder}
              hasAllAttendances={booleans.hasAllAttendances}
            />
          ) : (
            <></>
          )}

          {booleans.cancelAppointmentModal ? (
            <CancelAppointmentModal
              isOpen={booleans.cancelAppointmentModal}
              handleClose={() => handleCloseCancelModal()}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </PageContainer>
  );
};
